@import url('https://fonts.googleapis.com/css2?family=Goldman&display=swap');
.App {
  text-align: center;
}
body {
  background-color: black;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
img {
  width: 10vw;
  min-width: 100px;
}
.navbar {
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1em;
  margin-bottom: 1em;

}
.title{
  font-size: 3rem;
}
.title, .link {
  color: #f6eb0b;
  font-family: 'Goldman', cursive;
}


.home-container {

  position: relative;
  padding-top: 15%;
  padding-bottom: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 0.5; */
}

.home-container::before {
  content: '';
  background-image: url('images/IMG_1964.jpg');
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
}

.slide1 .slide2 .slide3 .slide4 {
  position: relative;

}
.slide1::before {
  content: '';
  background-image: url('images/IMG_1979.jpg');
  background-repeat: none;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom:0;
  opacity: 0.5;
}

.slide2::before {
  content: '';
  background-image: url('images/IMG_2007.jpg');
  background-repeat: none;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom:0;
  opacity: 0.5;
}
.slide3::before {
  content: '';
  background-image: url('images/IMG_1973.jpg');
  background-repeat: none;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom:0;
  opacity: 0.5;
}
.slide4::before {
  content: '';
  background-image: url('images/IMG_1970.jpg');
  background-repeat: none;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom:0;
  opacity: 0.5;
}

.list-child-col {
  position: relative;
  color: white;
  text-align: center;
}



#content-caption {
  font-family: 'Goldman', cursive;
  text-align: center;
  color: white;
  position: relative;
  font-size: 4em;
}
h1 {
  color: white;
}

h2{
  color: white;
}
.icon {
  color: white;
  font-size: 2.5rem;
}
.phone-header {
  color: white;
  margin-left: 1.5em;
}
.title-phone-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.email-facebook-wrapper {
  display: flex;
  flex-direction: column;
}
.email-facebook {
  margin: 0.5em;
}
.list-child {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding-top: 10%;
  padding-bottom: 10%;
}
.nav-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon:hover {
  transition: 0.25s;
  color: #a3872c;
}

a {
  text-decoration: none;
  color: white;
  text-align: center;
}
p {
  color:white;
}
li {
  font-size: 2.30rem;
  font-weight: 600;
  list-style-type: none;
  margin-top: 1em;
  margin-bottom: 1.5em;
  color: white;
  text-shadow: 10px 10px 20px black;
}

.list-container {
  display: flex;
  justify-content:space-around; 
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}



.services-container {
  /* background-image: url('images/IMG_2003.jpg'); */
  width: 100vw;
  

}
.category {
  margin-top: 1em;
}

.contact-container {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 4em;
  margin-bottom: 4em;
}
.address-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: top;
  flex-wrap: wrap;
}

address{
  font-size: 1.254rem;
}
.content-caption {
  color: white;
}

.opening-hours-heading {
  /* margin-top: 2em; */
  margin-bottom: 1.5em;
}
.hours {
  margin-top: 2em;
  font-size: 1.5em;
}

.address-image {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.sign{
  width: 500px;
  margin-bottom: 2em;
}

.final-call{
  position: relative;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.final-call::before{
    content: '';
    background-image: url('images/IMG_2009.jpg');
    background-repeat: none;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom:0;
    opacity: 0.5;
}

.final-call-text{
  font-family: 'Goldman', cursive;
  text-align: center;
  color: white;
  position: relative;
  font-size: 4em;
  
}

.link-services{
  margin-bottom: 0.5em;
}
.link-contact{
  margin-top: 0.5em;
}



@media screen and (max-width: 620px) {
  iframe {
    width:100vw;
  }
  #content-caption{
    font-size: 2.5em;
  }
  .title {
    text-align: center;
  }
  .list-container {
    justify-content: left;
  }
  .contact-container {
    justify-content: space-around;
    margin-left: 1em;
  
  }
  .contact-child {
    margin-right: 1em;
  }
  .email{
    align-self: flex-start;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .address-text{
    margin-left: 1.5em;
  }
  li{
    font-size: 1.30rem;
  }
  .sign{
    width:300px;
    margin-bottom: 2em;
  }
  .link-services{
    margin-bottom: 1em;
  }
  .link-contact{
    margin-top: 1em;
  }
}

